import React from 'react'
import SEO from '../../components/seo'
import SaleDetail from '../../components/MaskSaleDetail'
import styled from '@emotion/styled'

import { Link } from 'gatsby'
import { Container, CopperStripe } from '../../utils/styles';

import { breakpoints } from '../../utils/styles'
import { copper, skyBlue, oceanBlue, tealGreen } from '../../constants/theme';

import theicon1 from '../../images/welcome/icon-1.png'
import awardIcon from '../../images/welcome/mask/award-icon.png'
import filterExplain from '../../images/welcome/mask/filter-explain.gif'
import filterLayers from '../../images/welcome/mask/mask-layers.gif'
import headerImg from '../../images/welcome/mask/mask-header.jpg'
import maskPeek from '../../images/welcome/mask/mask-peek.jpg'
import nbcClip from '../../images/welcome/mask/nbc-clip.gif'
import maskPilots from '../../images/welcome/mask/pilots.jpg'
import maskTravel from '../../images/welcome/mask/travel-icons.png'

const WideImage = styled.img`
  max-width: 800px;
  width: 100%;
  display: block;
  margin: auto;
  margin-bottom: 30px;
`

export const ArthBlockStyle = styled.div`
  background-color: white;
  color: white;
  color: ${oceanBlue};
  padding: 40px;
  max-width: 1320px;
  margin: auto;
  text-align: center;

  img {
    width: 100%;
    max-width: 700px;
  }

  h1 {
    font-size: 46px;
    font-family: 'gilroyExtraBold';
    letter-spacing: 0.1rem;
    font-weight: normal;
    margin-top: 0;
    line-height: 1.12;
    margin-bottom: 30px;
    @media (max-width: ${breakpoints.s}px){
        font-size: 30px;
    }
  }

`;

export const TitleBlockStyle = styled.div`

  background-color: white;
  color: white;
  color: ${oceanBlue};
  padding: 30px 20px 10px 20px;
  max-width: 1320px;
  margin: auto;
  text-align: center;

  h1 {
    font-size: 46px;
    font-family: 'gilroyExtraBold';
    letter-spacing: 0.1rem;
    font-weight: normal;
    margin-top: 0;
    line-height: 1.12;
    margin-bottom: 30px;
    @media (max-width: ${breakpoints.s}px){
        font-size: 30px;
    }
  }
`

const BlockImg = styled.img`
  width: 100%;
  margin-bottom: 20px;
`

const ImageLinkBlock = (props) => (
  <Link to={props.to}><BlockImg alt={props.title} src={props.img} /></Link>
)

const ImageBlock = (props) => (
  <BlockImg alt={props.title} src={props.img} />
)


const TextBlock = ({to}) => {


const TextBlockStyle = styled.div`
  background-color: white;
  color: white;
  color: ${oceanBlue};
  padding: 20px 20px 28px 20px;
  max-width: 1320px;
  margin: auto;
  text-align: center;

  p {
    font-size: 16px;
    font-family: 'HKNova-Medium';
    letter-spacing: 0.1rem;
    line-height: 1.4;
    max-width: 720px;
    margin: auto;
    margin-bottom: 33px;
    a {
      color: ${oceanBlue};
      text-decoration: none;
    }
  }

  h1 {
    font-size: 46px;
    font-family: 'gilroyExtraBold';
    letter-spacing: 0.1rem;
    font-weight: normal;
    margin-top: 0;
    line-height: 1.12;
    margin-bottom: 20px;
    @media (max-width: ${breakpoints.s}px){
        font-size: 30px;
    }
  }

  h3 {
    letter-spacing: 0.350rem;
    font-family: 'gilroyExtraBold';
    text-transform: uppercase;
    font-weight: normal;
    margin-bottom: 11px;
    color: ${skyBlue};
  }
`;




return (
  <TextBlockStyle>
    <h3>Coppper Compression Mask</h3>
    <h1>The Traveling Essential</h1>
  </TextBlockStyle>
)


}



const SalesBlock = ({to}) => {


const SalesBlockStyle = styled.div`
  background-color: white;
  color: white;
  color: ${oceanBlue};
  padding: 20px 20px 28px 20px;
  max-width: 1320px;
  margin: auto;
  text-align: center;

  p {
    font-size: 16px;
    font-family: 'HKNova-Medium';
    letter-spacing: 0.1rem;
    line-height: 1.4;
    max-width: 720px;
    margin: auto;
    margin-bottom: 33px;
    a {
      color: ${oceanBlue};
      text-decoration: none;
    }
  }

  h1 {
    font-size: 46px;
    font-family: 'gilroyExtraBold';
    letter-spacing: 0.1rem;
    font-weight: normal;
    margin-top: 0;
    line-height: 1.12;
    margin-bottom: 20px;
    color: ${copper};
    @media (max-width: ${breakpoints.s}px){
        font-size: 30px;
    }
  }

  h3 {
    letter-spacing: 0.350rem;
    font-family: 'gilroyExtraBold';
    text-transform: uppercase;
    font-weight: normal;
    margin-bottom: 11px;
    color: ${oceanBlue};
  }

  a {
    display: inline-block;
    background-color: ${oceanBlue};
    padding: 10px 30px;
    color: white;
    border-radius: 5px;
    border: 2px solid ${copper};
    text-decoration: none;
    font-family: 'gilroyExtraBold';
    text-transform: uppercase;
    letter-spacing: 0.1rem;
  }
`;




return (
  <SalesBlockStyle>
    <h3>Coppper Compression Masks</h3>
    <h1>Designed to Protect</h1>
    <p>Our copper infused face mask is designed for respiratory protection.</p>
    <Link to={to}>Buy Now</Link>
  </SalesBlockStyle>
)


}

const ProtectBlock = ({icon}) =>  {

  const ProtectBlockExtra = styled.div`
    background-color: ${oceanBlue};
    padding: 40px;
    text-align: center;
    color: white;
    margin-bottom: 20px;
    p {
      font-size: 16px;
      font-family: 'HKNova-Medium';
      letter-spacing: 0.1rem;
      line-height: 1.4;
      max-width: 720px;
      margin: auto;
    }

    h1 {
      font-size: 46px;
      font-family: 'gilroyExtraBold';
      letter-spacing: 0.1rem;
      font-weight: normal;
      margin-top: 0;
      margin-bottom: 30px;
      line-height: 1.12;

      @media (max-width: ${breakpoints.s}px){
          font-size: 30px;
      }

    }

    h3 {
      letter-spacing: 0.350rem;
      font-family: 'gilroyExtraBold';
      text-transform: uppercase;
      font-weight: normal;
      margin-bottom: 21px;
      color: ${tealGreen};
      margin-left: 7px;
    }


  `

  return (
    <ProtectBlockExtra>
      <img src={awardIcon} alt='award icon' width='100' />
      <h3>Award Winning Design</h3>
      <h1>4 Layers of Protection</h1>
      <p>Our dual-filtered, copper-infused, anti-bacterial, , washable and reusable face masks won the Gold Stevie Award in the Consumer Products - Durables category. It was also named Gold winner in the Company of the Year - Consumer Products - Durables (small) category.</p>
    </ProtectBlockExtra>
  )

}

const TwoPic = ({pic1, pic2}) => { 

  const TwoPicWrap = styled.div`
    display:grid;
    margin-bottom: 20px;
    grid-template-columns: 1fr 1fr;
    @media (max-width: ${breakpoints.l}px){
      grid-template-columns: 1fr;
    }
  `
  const BlockImg = styled.img`
    width: 100%;
  `

  return (
    <TwoPicWrap>
      <div>
        <BlockImg src={pic1} />
      </div>
      <div>
        <BlockImg src={pic2} />
      </div>
    </TwoPicWrap>
  )

}

const LandingPage = () => {
  return (
    <>
    <CopperStripe />
    <Container>
      <SEO title="Arthritis Awareness Month!" description="Arthritis is a term used to describe inflammation, pain, and stiffness in your joints. There are more than 100 different types of arthritis and related conditions that affect different parts of the body." keywords={ [`copper`, `compression`, `pain relief`, `strength to heal`] } />

      <ImageBlock img={headerImg} title="Copper Compression Masks" />

      <SalesBlock  to='/collections/mask' />

      <ProtectBlock icon={theicon1} />

      <TwoPic pic1={filterExplain} pic2={filterLayers} />

      <SaleDetail tag='Ultimate Protection' title='For the Whole family'/>

      <ImageLinkBlock img={nbcClip} alt='Nbc News'/>

      <ImageLinkBlock img={maskPeek} alt='A peek at your mask' to='/collections/mask'/>

      <TextBlock  to='/collections/mask' />
      <ImageBlock alt='Mask Travel' img={maskTravel} title="Copper Compression Masks" />
      <WideImage src={maskPilots} title="Copper Compression Masks" />


    </Container>
    </>
  )
}

export default LandingPage
